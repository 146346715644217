import React from 'react'
import { useEffect, useState, useRef } from 'react'

import {Link} from 'gatsby'

//assets
import findYourBottleBg from '../../../assets/images/brands/curious-incident/find-your-bottle-bg.png'

import { isElementInViewport } from '../../functions'

const FindYourBottleSection = () => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    return (
        <section
            className={(isInView)? 'find-your-bottle d-flex justify-content-center align-items-center in-view' : 'find-your-bottle d-flex justify-content-center align-items-center'} 
            ref={ref}
            style={{backgroundImage: `url(${findYourBottleBg})`}}>
            <div className='container text-center slide-up'>
                <h3 className="text-uppercase">Find your bottle of Curious Incident</h3>
                <Link to="/find-your-wine" title='Go to Wine Locator'>
                    <button className='btn btn-primary-2 mb-0'>Go to Wine Locator</button>
                </Link>
            </div>
        </section>
    )
}

export default FindYourBottleSection